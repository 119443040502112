import { Injectable } from '@angular/core';
import { ITaxInfoModel, IPropertyProtectionCoverageModel, IUnitDistributionHead, IDistributedAmountDetails, ITaxDistribution, IDistributedResponse, IUnitHeadTaxPayment
} from './models/ProspectModel';
import { EApprovalsDMRM, TaxAppliedFor, EnumMerchDiscount, EnumDiscount, ECreditCards, EPaymentTransactionType, EPaymentModes } from './enum';
import { ProspectService } from '../services/operations/prospects.service';
import { IDuesModel, IAmountCreditInputModel, IDueHeadDetails, IAmountCreditOuputModel, IFeesDueHeadDetails, IInsuranceDueHeadDetails, IMerchandiseDueHeadDetails } from './models/CustomerModel';
import { DatePipe, DecimalPipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class CommonMethodService {
    _MS_PER_DAY = 1000 * 60 * 60 * 24;
    discountData;
    distributedAmount;
    cvrgModel: IPropertyProtectionCoverageModel = {
        Id: 5,
        Name: 'PP',
        Status: 0,
        SelectedInsurance: true,
        CoverageAmount: 456,
        CoveragePercent: 77,
        PremiumAmount: 1500,
        InsuranceNumber: '',
        ProrateAtMoveIn: true,
        ProrateAtMoveOut: true,
        ProrateAtCancel: false,
        ProrateAtApply: false,
        ProrateAtTransfer: false,
        Inactive: true,
        SelectedInsuranceIdPending: 0,
        SelectedInsuranceforPending: false,
        EffectiveDate: new Date(),
        InsuranceAllowance: {
            Allowance: 0
        },
        DefaultInsScheme: true,
        CompanyName: '',
        ExpiryDate: new Date(),
    };
    EApprovalsDMRM = EApprovalsDMRM;
    // Mock Data
    constructor(private prospectService: ProspectService,
        public decimalPipe: DecimalPipe) { }

    public dateDiff(date1, date2) {
        const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
        if (date1 > date2) {
            return Math.floor((utc1 - utc2) / this._MS_PER_DAY);
        } else {
            return Math.floor((utc2 - utc1) / this._MS_PER_DAY);
        }
    }
    public async getTaxValue(inputTax: ITaxInfoModel[], amount: number) {
        if (amount == 0 || amount == null || amount == undefined || inputTax.length == 0) {
            return 0.00
        } else {
            try {
                let taxValue = await this.prospectService.getTaxValueFromAPI(inputTax, amount).then(taxvalue => {
                    return taxvalue;
                });
                return taxValue;
            }
            catch(err) {
                console.log(err.message);
                return 0.00
            }
        }
    }

    public async getEachTaxValue(inputTax: ITaxInfoModel[], amount: number) {
        if (amount == 0 || amount == null || amount == undefined || inputTax.length == 0) {
            return inputTax
        } else {
            try {
                let user = await this.prospectService.getEachTaxValueFromAPI(inputTax, amount).then(taxvalue => {
                    return taxvalue;
                });
                return user;
            }
            catch(err) {
                console.log(err.message);
                return inputTax
            }
        }
    }

    public getProratedValue(billingType: number, value: number, moveIndate: string, billingDay: number, NextWeeklyClose: Date) {
        // In JS Months starts from 0 to 11
        var TotalDays = 0;
        var RemainingDays = 0;

        var endDate = new Date();
        var tempEndDate = new Date();
        var startDate = new Date();
        var dtmMoveInDate;
        if (moveIndate.length == 0) {
            dtmMoveInDate = new Date();
        } else {
            dtmMoveInDate = new Date(moveIndate);
        }

        // Method Logic: Calculate the remaining days in that period based on the BillingCycle
        // And apply the prorate (i.e., InsuranceAmount/TotalDays * RemainingDays)
        if (billingType == 1) // If BillingType is Annual
        {
            endDate = new Date(new Date().getFullYear(), 11, 31);
            startDate = new Date(new Date().getFullYear(), 0, 1);

        } else if (billingType == 2) {// When Billing type is Biannual


            if (new Date().getMonth() >= 0 && new Date().getMonth() <= 5) {
                endDate = new Date(new Date().getFullYear(), 5, 30);
                startDate = new Date(new Date().getFullYear(), 0, 1);
            }
            else if (new Date().getMonth() >= 6 && new Date().getMonth() <= 11) {
                endDate = new Date(new Date().getFullYear(), 11, 31);
                startDate = new Date(new Date().getFullYear(), 6, 1);
            }

        } else if (billingType == 3) {// When Billing type is Quarterly

            if (new Date().getMonth() >= 0 && new Date().getMonth() <= 2) {
                endDate = new Date(new Date().getFullYear(), 2, 31);
                startDate = new Date(new Date().getFullYear(), 0, 1);
            }
            else if (new Date().getMonth() >= 3 && new Date().getMonth() <= 5) {
                endDate = new Date(new Date().getFullYear(), 5, 30);
                startDate = new Date(new Date().getFullYear(), 3, 1);
            }
            else if (new Date().getMonth() >= 6 && new Date().getMonth() <= 8) {
                endDate = new Date(new Date().getFullYear(), 8, 30);
                startDate = new Date(new Date().getFullYear(), 6, 1);
            }
            else if (new Date().getMonth() >= 9 && new Date().getMonth() <= 11) {
                endDate = new Date(new Date().getFullYear(), 11, 31);
                startDate = new Date(new Date().getFullYear(), 9, 1);
            }
        } else if (billingType == 4) { // When Billing type is Monthly

            startDate = new Date(dtmMoveInDate.getFullYear(), dtmMoveInDate.getMonth(), 1);
            endDate = new Date(dtmMoveInDate.getFullYear(), dtmMoveInDate.getMonth() + 1, startDate.getDate() - 1);

        } else if (billingType == 5) { // When Billing type is 28 Days
            endDate = new Date(NextWeeklyClose);
            endDate = new Date(endDate.setDate(endDate.getDate() + 21));
            tempEndDate = new Date(endDate);
            while (dtmMoveInDate > endDate) {
                endDate = new Date(endDate.setDate(endDate.getDate() + 28));
                tempEndDate = new Date(endDate);
            }

            startDate = new Date(tempEndDate.setDate(tempEndDate.getDate() - 27));
            console.log('EndDate in BT loop', endDate);
        } else if (billingType == 6) { // When Billing type is weekly
            endDate = new Date(NextWeeklyClose);
            tempEndDate = new Date(endDate);
            while (dtmMoveInDate > endDate) {
                endDate = new Date(endDate.setDate(endDate.getDate() + 7));
                tempEndDate = new Date(endDate);
            }
            startDate = new Date(tempEndDate.setDate(tempEndDate.getDate() - 6));
        } else if (billingType == 7) { // When Billing type is Daily
            return value;
        }
        console.log('EndDate before total days', endDate);
        TotalDays = this.dateDiff(endDate, startDate) + 1; // This gives the total no of days
        RemainingDays = this.dateDiff(endDate, dtmMoveInDate) + 1;
        var ProratedValue = ((value / TotalDays) * RemainingDays);

        return ProratedValue;
    }


    async DistributeAllowanceAmount(taxInput: ITaxInfoModel[], amount: number, allowance: number) {
        let distributedAmount: IDistributedAmountDetails = {
            UnitHeadAllowance: 0,
            UnitAllowance: 0,
            CostComp: [],
            TaxComp: [],
            CostTaxComp: [],
            UnitHeadPayment: 0,
            UnitHeadTaxPayment: 0,
            ChargeId: 0,
            RecurringFee: 0,
            RemainingAllowanceAmount: 0,
            RemainingTaxHeadDues: []
        };
        let costComp = 0;
        let calculatedcostComp = 0;
        let taxComp = 0;
        let calculatedtaxComp = 0;
        let costTaxComp = 0;
        let calculatedcostTaxComp = 0;
        let taxTotal = 0;
        let totalAmount = 0;
        var costCompTaxList: IUnitDistributionHead[] = [];
        var taxCompTaxList: IUnitDistributionHead[] = [];
        var costTaxCompTaxList: IUnitDistributionHead[] = [];

        let tax: ITaxInfoModel[] = taxInput.filter(taxModel => {return taxModel});
        tax = await this.getEachTaxValue(tax, amount);
        tax.forEach(element => {
            if (element.TaxAppliedFor == TaxAppliedFor.CostComponent) {
                costComp += element.AppliedTaxAmount;
            }
        });

        tax.forEach(element => {
            if (element.TaxAppliedFor == TaxAppliedFor.TaxComponent) {
                taxComp += element.AppliedTaxAmount;
            }
        });

        tax.forEach(element => {
            if (element.TaxAppliedFor == TaxAppliedFor.CostandTaxComponent) {
                costTaxComp += element.AppliedTaxAmount;
            }
        });

        taxTotal = costComp + taxComp + costTaxComp;
        totalAmount = amount + taxTotal;

        var allowancePrercentage = parseFloat(((allowance * 100) / totalAmount).toFixed(2)); //Math.round()
        var unitHeadallowance = Number(((amount * allowancePrercentage) / 100).toFixed(2));
        let taxAllowanceAmount
        if (allowance != 0) {
            taxAllowanceAmount = Number(((amount * allowancePrercentage) / 100).toFixed(2));
        } else {
            taxAllowanceAmount = amount;
        }
        tax = await this.getEachTaxValue(tax, taxAllowanceAmount);
        tax.forEach((element) => {
            let unitDistributionHead: IUnitDistributionHead = {
                Amount: 0,
                ChargesTaxId: 0,
                TaxId: 0,
                AllowanceAmount: 0
            };
            if (element.TaxAppliedFor == TaxAppliedFor.CostComponent) {
                let costCompAllowance = 0;
                costCompAllowance = element.AppliedTaxAmount;
                unitDistributionHead.Amount = costCompAllowance;
                unitDistributionHead.TaxId = element.Id;
                calculatedcostComp += costCompAllowance;
                costCompTaxList.push(unitDistributionHead);
            }
            if (element.TaxAppliedFor == TaxAppliedFor.TaxComponent) {
                let taxCompAllowance = 0;
                taxCompAllowance = element.AppliedTaxAmount;
                unitDistributionHead.Amount = taxCompAllowance;
                unitDistributionHead.TaxId = element.Id;
                taxCompTaxList.push(unitDistributionHead);
                calculatedtaxComp += taxCompAllowance;
            }
            if (element.TaxAppliedFor == TaxAppliedFor.CostandTaxComponent) {
                let costTaxCompAllowance = 0;
                costTaxCompAllowance = element.AppliedTaxAmount;
                unitDistributionHead.Amount = costTaxCompAllowance;
                unitDistributionHead.TaxId = element.Id;
                costTaxCompTaxList.push(unitDistributionHead);
                calculatedcostTaxComp += costTaxCompAllowance;
            }
        });
        let totalUnitAllowance = 0;
        let unitAllowanceTax = 0;
        if (allowance != 0) {
            totalUnitAllowance = Number((unitHeadallowance + calculatedcostComp + calculatedtaxComp + calculatedcostTaxComp).toFixed(2));
            unitAllowanceTax = Number((calculatedcostComp + calculatedtaxComp + calculatedcostTaxComp).toFixed(2));
            if (parseFloat(totalUnitAllowance.toFixed(2)) != parseFloat(allowance.toFixed(2))) {
                var calculatedAllowance = parseFloat(totalUnitAllowance.toFixed(2));
                var appliedallowance = parseFloat(allowance.toFixed(2));
                var mismatch = appliedallowance - calculatedAllowance;
                if (appliedallowance < calculatedAllowance) { // appliedallowance is less than calculatedAllowance.
                    calculatedcostComp += mismatch;
                }
                if (appliedallowance > calculatedAllowance) { // appliedallowance is greater than calculatedAllowance.
                    calculatedcostComp += mismatch;
                }
                totalUnitAllowance = Number((unitHeadallowance + calculatedcostComp + calculatedtaxComp + calculatedcostTaxComp).toFixed(2));
                unitAllowanceTax = Number((calculatedcostComp + calculatedtaxComp + calculatedcostTaxComp).toFixed(2));
            }
        }
        var unitAmountToPay = amount - unitHeadallowance;
        var unitTaxToPay = taxTotal - unitAllowanceTax;
        var checkresult = totalAmount - allowance;
        var calculatedResult = Number(unitAmountToPay.toFixed(2)) + Number(unitTaxToPay.toFixed(2));
        if (//Modified below condition to fix rounding issue where distributed amount was returning null
            (
                parseFloat(checkresult.toFixed(2)) == parseFloat(calculatedResult.toFixed(2))
                || Math.abs(parseFloat((parseFloat(checkresult.toFixed(2)) - parseFloat(calculatedResult.toFixed(2))).toFixed(2))) == 0.01
            )
            &&
            parseFloat(totalUnitAllowance.toFixed(2)) == parseFloat(allowance.toFixed(2))
        ) {
            distributedAmount.UnitAllowance = parseFloat(totalUnitAllowance.toFixed(2));
            distributedAmount.UnitHeadAllowance = parseFloat(unitHeadallowance.toFixed(2));
            distributedAmount.UnitHeadPayment = parseFloat(unitAmountToPay.toFixed(2));
            distributedAmount.UnitHeadTaxPayment = parseFloat(unitTaxToPay.toFixed(2));
            distributedAmount.CostComp = costCompTaxList;
            distributedAmount.TaxComp = taxCompTaxList;
            distributedAmount.CostTaxComp = costTaxCompTaxList;
        } else {
            return null;
        }
        return distributedAmount;

    }

    ////////////////++++++api datetime to local date without Time part+++++++++ //////////////////////////////
    // example movein date, moveout date
    //Use only in case where only date part is needed, and no local or UTC formates are needed
    // input in format "2022-10-14T05:03:52.787Z", returns Date in angular Date format new Date()
    getAPIDateTimeToLocalDatePart(apiDatetimeString) {
        if (apiDatetimeString != null && apiDatetimeString != undefined && apiDatetimeString != '') {
            const year = parseInt(apiDatetimeString.substr(0, 4), 10);
            const month = parseInt(apiDatetimeString.substr(5, 2), 10);
            const day = parseInt(apiDatetimeString.substr(8, 2), 10);
            return new Date(year, month - 1, day, 0, 0, 0);
        } else {
            return apiDatetimeString;
        }
    }

    getDateTimeWithoutTimeForApi(date) { // input should be in angular date format ( new Date() or 'MM/dd/yyyy'), returns Date in format - "2022-11-01T00:00:00Z", 
        if (date != null && date != undefined && date != '') {
            date = new Date(date);
            const datepipe: DatePipe = new DatePipe('en');
            let dateStr = datepipe.transform(date, 'yyyy-MM-dd');
            return dateStr + 'T00:00:00.000z';
        } else {
            return date;
        }
    }

    ////////////////+++++++Date Settings+++++++++ //////////////////////////////
    getTimeZoneOffSetDate(date) {
        if (date != null || date != undefined) {
            let givenDate = new Date(date);
            return new Date(givenDate.getTime() + Math.abs(givenDate.getTimezoneOffset() * 60000));
        }
    }
    ///Type///
    // 1=> EffectiveDate
    // 2 => Stop Date
    // 3 => Get Effective/Stop Date
    getDateState(calenderDate, nextBillingDate, billingType, billingCycle, type) {
        let targetDate;
        let nextBillDate = this.getTimeZoneOffSetDate(nextBillingDate);
        if (billingType === 1) { //Yearly/Anually
            if (billingCycle === 162) { //anniversary
                targetDate = new Date(nextBillDate.getFullYear() + 1);
            } else if (billingCycle === 163) { //FOM
                targetDate = new Date(nextBillDate.getFullYear() + 1);
                targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
            }
            if (targetDate == calenderDate) {
                return type === 3 ? targetDate : false;
            }
            return type === 3 ? targetDate : true;
        } else if (billingType === 2) {
            if (billingCycle === 162) {
                targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 6));
            } else if (billingCycle === 163) {
                targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 6));
                targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
            }
            if ((targetDate.getDate() == calenderDate.getDate() && targetDate.getMonth() == calenderDate.getMonth())
                || (nextBillDate.getDate() == calenderDate.getDate() && nextBillDate.getMonth() == calenderDate.getMonth())) {
                return type === 3 ? targetDate : false;
            }
            return type === 3 ? targetDate : true;
        } else if (billingType === 3) {
            let secondQuater;
            let thirdQuater;
            if (billingCycle === 162) {
                targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 3));
                secondQuater = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 6));
                thirdQuater = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 9));
            } else if (billingCycle === 163) {
                targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 3));
                secondQuater = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 6));
                thirdQuater = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 9));
                targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
                secondQuater = new Date(secondQuater.getFullYear(), secondQuater.getMonth(), 1);
                thirdQuater = new Date(thirdQuater.getFullYear(), thirdQuater.getMonth(), 1);
            }
            if ((targetDate.getDate() == calenderDate.getDate() && targetDate.getMonth() == calenderDate.getMonth())
                || (secondQuater.getDate() == calenderDate.getDate() && secondQuater.getMonth() == calenderDate.getMonth())
                || (thirdQuater.getDate() == calenderDate.getDate() && thirdQuater.getMonth() == calenderDate.getMonth())
                || (nextBillDate.getDate() == calenderDate.getDate() && nextBillDate.getMonth() == calenderDate.getMonth())) {
                return type === 3 ? targetDate : false;
            }
            return type === 3 ? targetDate : true;
        } else if (billingType === 4) {
            if (billingCycle === 162) {
                targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 1));
                if (type === 2) {
                    if (targetDate.getDate() === 1) {
                        targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() - 1));
                        targetDate = this.getLastDayOfMonth(targetDate);
                    } else {
                        targetDate = new Date(targetDate.getDate() - 1);
                    }

                }
            } else if (billingCycle === 163) {
                targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 1));
                targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
                if (type === 2) {
                    if (targetDate.getDate() === 1) {
                        targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() - 1));
                        targetDate = this.getLastDayOfMonth(targetDate);
                    } else {
                        targetDate = new Date(targetDate.getDate() - 1);
                    }
                }
            }
            if (targetDate.getDate() == calenderDate.getDate()) {
                return type === 3 ? targetDate : false;
            }
            return type === 3 ? targetDate : true;
        }
    }

    getEffectiveDates(calenderDate, nextBillingDate, billingType, billingCycle, type) {
        let targetDate;
        let dateList = [];
        let nextBillDate = this.getTimeZoneOffSetDate(nextBillingDate);
        if (billingType === 1) { //Yearly/Anually
            let dateFactor = Math.floor(365 / 365);
            if (billingCycle === 162) { //anniversary
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setFullYear(nextBillDate.getFullYear() + 1));
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            } else if (billingCycle === 163) { //FOM
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setFullYear(nextBillDate.getFullYear() + 1))
                    targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            }
        } else if (billingType === 2) { //Bi Annual
            let dateFactor = Math.floor(365 / 182);
            if (billingCycle === 162) {
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 6));
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            } else if (billingCycle === 163) {
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 6));
                    targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            }
        } else if (billingType === 3) { //Quaterly
            let dateFactor = Math.floor(365 / 91);
            if (billingCycle === 162) {
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 3));
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            } else if (billingCycle === 163) {
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 3));
                    targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            }
        } else if (billingType === 4) {//Monthly
            let dateFactor = Math.floor(365 / 30);
            if (billingCycle === 162) {
                dateList = this.getListOfDatesInYear(nextBillDate.getDate());
                if (type === 2) {
                    let day = nextBillDate.getDate() - 1;
                    dateList = this.getListOfDatesInYear(day);
                }
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 1));
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                    if (this.isLeapYear(targetDate.getFullYear())) {
                        let leapYearDate = new Date(targetDate.getFullYear(), 1, 29);
                        dateList.push(leapYearDate);
                    }
                    dateList.push(targetDate);
                });
                return dateList;

            } else if (billingCycle === 163) {
                // dateList.push(new Date(nextBillDate.getFullYear(), nextBillDate.getMonth(), 1));
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setMonth(nextBillDate.getMonth() + 1));
                    targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            }
        } else if (billingType === 5) {// 28 days
            let dateFactor = Math.floor(365 / 28);
            if (billingCycle === 162) {
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setDate(nextBillDate.getDate() + 28));
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            } else if (billingCycle === 163) {
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setDate(nextBillDate.getDate() + 28));
                    targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            }
        } else if (billingType === 6) { // Weekly
            let dateFactor = Math.floor(365 / 7);
            if (billingCycle === 162) {
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setDate(nextBillDate.getDate() + 7));
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            } else if (billingCycle === 163) {
                this.times(dateFactor)(() => {
                    targetDate = new Date(nextBillDate.setDate(nextBillDate.getDate() + 7));
                    targetDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
                    if (type === 2) {
                        targetDate.setDate(targetDate.getDate() - 1);
                    }
                    dateList.push(targetDate);
                });
                return dateList;
            }
        }
    }

    isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    }

    getLastDayOfMonth(date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    times = x => f => {
        if (x > 0) {
            f()
            this.times(x - 1)(f)
        }
    }

    getListOfDatesInYear(day) {
        let i = 0;
        let dateList = [];
        this.times(12)(() => {
            let date = new Date();
            if (i == 1 && day > 28) {
                date.setMonth(1);
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                dateList.push(lastDay);
            } else {
                date.setMonth(i);
                date.setDate(day);
                dateList.push(date);
            }
            i++;
        });
        return dateList;
    }
    ////////////////-----------Date Settings-------------- //////////////////////////////


    ///////================Transfer Amount Distribution=================//////////////
    getAmountCredited(paymentOrder: any[], amountCreditInput: IAmountCreditInputModel) {
        let remainingAmount = 0;
        let duesreturn: IDuesModel = <IDuesModel>{};
        let periodCycle = 0;
        duesreturn.Rent = [];
        duesreturn.Fees = [];
        duesreturn.Insurance = [];
        duesreturn.Merchandise = [];
        duesreturn.LateLienCharge = [];
        remainingAmount = amountCreditInput.Allowance * -1;
        // var distributedAmount: IDistributedAmountDetails;
        if (remainingAmount > 0) {
            if (amountCreditInput.Dues.Deposit.Amount > 0) {
                duesreturn.Deposit = {
                    Amount: 0,
                    Name: '',
                    Tax: [],
                    Id: 0,
                    FromDate: new Date(),
                    ToDate: new Date(),
                    Head: 0,
                    RentalChargeId: 0,
                    LedgerId: 0,
                    AmountPaid: 0,
                    AllowancePaid: 0,
                    AmountToPay: 0,
                    LateLienDate: new Date(),
                    PaidThroughDate: new Date()
                };
                if (remainingAmount > amountCreditInput.Dues.Deposit.Amount) {
                    duesreturn.Deposit.Amount = amountCreditInput.Dues.Deposit.Amount;
                } else {
                    duesreturn.Deposit.Amount = remainingAmount;
                }

                if (amountCreditInput.Dues.Deposit.Amount < remainingAmount) {
                    amountCreditInput.Dues.Deposit.Amount = 0;
                }
                else {
                    amountCreditInput.Dues.Deposit.Amount = amountCreditInput.Dues.Deposit.Amount - remainingAmount;
                }
                remainingAmount = remainingAmount - duesreturn.Deposit.Amount;
                duesreturn.Deposit = {
                    Amount: duesreturn.Deposit.Amount,
                    Name: '',
                    Tax: [],
                    Id: amountCreditInput.Dues.Deposit.Id,
                    FromDate: new Date(),
                    ToDate: new Date(),
                    Head: 0,
                    RentalChargeId: 0,
                    LedgerId: 0,
                    AmountPaid: 0,
                    AllowancePaid: 0,
                    AmountToPay: 0,
                    LateLienDate: new Date(),
                    PaidThroughDate: new Date()
                };
                //remainingAmount = distributedAmount.RemainingAllowanceAmount;
                // amountCreditInput.Dues.Deposit.Amount = amountCreditInput.Dues.Deposit.Amount - remainingAmount;//distributedAmount.UnitHeadPayment;
            }
            let intIndex = 0;
            let intPayOrderIndex = 0;

            let arrPaymentOrderSequence = paymentOrder;

            var rentDueList: IDueHeadDetails;
            var feeDueList: IFeesDueHeadDetails;
            var insDueList: IInsuranceDueHeadDetails;
            var merchDueList: IMerchandiseDueHeadDetails;
            var lateLienDueList: IDueHeadDetails;
            if (arrPaymentOrderSequence != null && arrPaymentOrderSequence.length != 0) {
                for (intIndex = intPayOrderIndex; intIndex < arrPaymentOrderSequence.length; intIndex++) {
                    if (remainingAmount > 0) {
                        periodCycle++;
                        switch (arrPaymentOrderSequence[intIndex]) {
                            case "1": // For Rent
                                // try
                                // {
                                amountCreditInput.Dues.Rent.forEach(rent => {
                                    if (rent.Amount > 0 && remainingAmount > 0) {
                                        let costCompTaxList: IUnitDistributionHead[] = [];
                                        let taxCompTaxList: IUnitDistributionHead[] = [];
                                        let costTaxCompTaxList: IUnitDistributionHead[] = [];
                                        rent.Tax.sort(o => o.TaxAppliedFor);
                                        rent.Tax.forEach(tax => {
                                            let taxHeadDistribution: IUnitDistributionHead = <IUnitDistributionHead>{};
                                            if (tax.TaxAppliedFor == TaxAppliedFor.CostComponent) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                costCompTaxList.push(taxHeadDistribution);
                                            }

                                            if (tax.TaxAppliedFor == TaxAppliedFor.TaxComponent) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                taxCompTaxList.push(taxHeadDistribution);
                                            }

                                            if (tax.TaxAppliedFor == TaxAppliedFor.CostandTaxComponent) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                costTaxCompTaxList.push(taxHeadDistribution);
                                            }
                                        });
                                        let taxDistribution: ITaxDistribution = <ITaxDistribution>{};
                                        taxDistribution.CostComp = costCompTaxList;
                                        taxDistribution.TaxComp = taxCompTaxList;
                                        taxDistribution.CostTaxComp = costTaxCompTaxList;
                                        let dueHeadDetails: IDueHeadDetails = <IDueHeadDetails>{};

                                        rent.Tax = rent.Tax && rent.Tax.length ? rent.Tax : [];
                                        var paymentDistribution = this.paymentDistribution(remainingAmount, rent.Amount, rent.Tax.reduce((total, t) => +total + t.AppliedTaxAmount, 0), taxDistribution);
                                        dueHeadDetails.Amount = (-1) * paymentDistribution.UnitHeadPayment;
                                        dueHeadDetails.Id = rent.Id;
                                        dueHeadDetails.Tax = [];
                                        remainingAmount = paymentDistribution.RemainingAmountToPay;
                                        rent.Amount = paymentDistribution.HeadAmountToBeDistributed;
                                        rent['taxAmountToBeDistributed'] = paymentDistribution.TotalTaxToBeDistributed;
                                        //fees.Tax = distributedAmount.RemainingTaxHeadDues;
                                        rent.Tax.map(dueTax => {
                                            if (dueTax.TaxAppliedFor == TaxAppliedFor.CostComponent) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.CostCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }

                                            if (dueTax.TaxAppliedFor == TaxAppliedFor.TaxComponent) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.TaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }
                                            if (dueTax.TaxAppliedFor == TaxAppliedFor.CostandTaxComponent) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.CostTaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }

                                        });

                                        var costComp = [];
                                        paymentDistribution.CostCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = <ITaxInfoModel>{};
                                            obj.TaxChargeId = ele.ChargesTaxId,
                                                obj.Id = ele.Id,
                                                obj.AppliedTaxAmount = (-1) * ele.TaxAmountToPay,
                                                obj.Percentage = 0,
                                                obj.TaxAppliedFor = 0,
                                                obj.fromDate = '',
                                                obj.toDate = ''
                                            costComp.push(obj);
                                        });

                                        var taxComp = [];
                                        paymentDistribution.TaxCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };

                                            taxComp.push(obj);
                                        });
                                        var costTaxComp = [];
                                        paymentDistribution.CostTaxCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                TaxAppliedFor: 0,
                                                Percentage: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };

                                            costTaxComp.push(obj);
                                        });
                                        if (costComp.length > 0) {
                                            dueHeadDetails.Tax = costComp;
                                        }

                                        if (taxComp.length > 0) {
                                            Object.assign(dueHeadDetails.Tax, taxComp); // dueHeadDetails.Tax.push(taxComp);
                                        }

                                        if (costTaxComp.length > 0) {
                                            Object.assign(dueHeadDetails.Tax, costTaxComp);//dueHeadDetails.Tax.push(costTaxComp);
                                        }

                                        rentDueList = dueHeadDetails;
                                        if (remainingAmount == 0) {

                                        }

                                    }

                                });
                                break;


                            case "2": // For Fees ,Late Fees,Lien Charge
                                amountCreditInput.Dues.Fees.forEach(fee => {
                                    if (fee.Amount > 0 && remainingAmount > 0) {
                                        let costCompTaxList: IUnitDistributionHead[] = [];
                                        let taxCompTaxList: IUnitDistributionHead[] = [];
                                        let costTaxCompTaxList: IUnitDistributionHead[] = [];
                                        fee.Tax.sort(o => o.TaxAppliedFor);
                                        fee.Tax.forEach(tax => {
                                            let taxHeadDistribution: IUnitDistributionHead = <IUnitDistributionHead>{};
                                            if (tax.TaxAppliedFor == TaxAppliedFor.CostComponent) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                costCompTaxList.push(taxHeadDistribution);
                                            }

                                            if (tax.TaxAppliedFor == TaxAppliedFor.TaxComponent) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                taxCompTaxList.push(taxHeadDistribution);
                                            }

                                            if (tax.TaxAppliedFor == TaxAppliedFor.CostandTaxComponent) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                costTaxCompTaxList.push(taxHeadDistribution);
                                            }
                                        });
                                        let taxDistribution: ITaxDistribution = <ITaxDistribution>{};
                                        taxDistribution.CostComp = costCompTaxList;
                                        taxDistribution.TaxComp = taxCompTaxList;
                                        taxDistribution.CostTaxComp = costTaxCompTaxList;
                                        let dueHeadDetails: IFeesDueHeadDetails = <IFeesDueHeadDetails>{};

                                        fee.Tax = fee.Tax && fee.Tax.length ? fee.Tax : [];
                                        var paymentDistribution = this.paymentDistribution(remainingAmount, fee.Amount, fee.Tax.reduce((total, t) => +total + t.AppliedTaxAmount, 0), taxDistribution);
                                        dueHeadDetails.Amount = (-1) * paymentDistribution.UnitHeadPayment;
                                        dueHeadDetails.Id = fee.Id;
                                        dueHeadDetails.Tax = [];
                                        remainingAmount = paymentDistribution.RemainingAmountToPay;
                                        fee.Amount = paymentDistribution.HeadAmountToBeDistributed;
                                        fee['taxAmountToBeDistributed'] = paymentDistribution.TotalTaxToBeDistributed;

                                        fee.Tax.map(dueTax => {
                                            if (dueTax.TaxAppliedFor == 155) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.CostCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }

                                            if (dueTax.TaxAppliedFor == 156) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.TaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }
                                            if (dueTax.TaxAppliedFor == 157) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.CostTaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }

                                        });
                                        var costComp = [];
                                        paymentDistribution.CostCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            costComp.push(obj);
                                        });
                                        var taxComp = [];
                                        paymentDistribution.TaxCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            taxComp.push(obj);
                                        });
                                        var costTaxComp = [];
                                        paymentDistribution.CostTaxCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            costTaxComp.push(obj);
                                        });
                                        if (costComp.length > 0) {
                                            dueHeadDetails.Tax = costComp;
                                        }

                                        if (taxComp.length > 0) {
                                            Object.assign(dueHeadDetails.Tax, taxComp);
                                        }

                                        if (costTaxComp.length > 0) {
                                            Object.assign(dueHeadDetails.Tax, costTaxComp);
                                        }

                                        feeDueList = dueHeadDetails;
                                        if (remainingAmount == 0) {

                                        }
                                    }
                                });
                                break;

                            case "3": // For Insurance
                                amountCreditInput.Dues.Insurance.forEach(ins => {

                                    if (ins.Amount > 0 && remainingAmount > 0) {
                                        let costCompTaxList: IUnitDistributionHead[] = [];
                                        let taxCompTaxList: IUnitDistributionHead[] = [];
                                        let costTaxCompTaxList: IUnitDistributionHead[] = [];
                                        ins.Tax.sort(o => o.TaxAppliedFor);
                                        ins.Tax.forEach(tax => {
                                            let taxHeadDistribution: IUnitDistributionHead = <IUnitDistributionHead>{};
                                            if (tax.TaxAppliedFor == 155) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                costCompTaxList.push(taxHeadDistribution);
                                            }

                                            if (tax.TaxAppliedFor == 156) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                taxCompTaxList.push(taxHeadDistribution);
                                            }

                                            if (tax.TaxAppliedFor == 157) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                costTaxCompTaxList.push(taxHeadDistribution);
                                            }
                                        });
                                        let taxDistribution: ITaxDistribution = <ITaxDistribution>{};
                                        taxDistribution.CostComp = costCompTaxList;
                                        taxDistribution.TaxComp = taxCompTaxList;
                                        taxDistribution.CostTaxComp = costTaxCompTaxList;
                                        let dueHeadDetails: IInsuranceDueHeadDetails = <IInsuranceDueHeadDetails>{};

                                        ins.Tax = ins.Tax && ins.Tax.length ? ins.Tax : [];
                                        var paymentDistribution = this.paymentDistribution(remainingAmount, ins.Amount, ins.Tax.reduce((total, t) => +total + t.AppliedTaxAmount, 0), taxDistribution);
                                        dueHeadDetails.Amount = (-1) * paymentDistribution.UnitHeadPayment;
                                        dueHeadDetails.Id = ins.Id;
                                        dueHeadDetails.Tax = [];
                                        remainingAmount = paymentDistribution.RemainingAmountToPay;
                                        ins.Amount = paymentDistribution.HeadAmountToBeDistributed;
                                        ins['taxAmountToBeDistributed'] = paymentDistribution.TotalTaxToBeDistributed;

                                        ins.Tax.map(dueTax => {
                                            if (dueTax.TaxAppliedFor == 155) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.CostCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }

                                            if (dueTax.TaxAppliedFor == 156) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.TaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }
                                            if (dueTax.TaxAppliedFor == 157) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.CostTaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }

                                        });

                                        var costComp = [];
                                        paymentDistribution.CostCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            costComp.push(obj);
                                        });
                                        var taxComp = [];
                                        paymentDistribution.TaxCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            taxComp.push(obj);
                                        });
                                        var costTaxComp = [];
                                        paymentDistribution.CostTaxCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            costTaxComp.push(obj);
                                        });
                                        if (costComp.length > 0) {
                                            dueHeadDetails.Tax = costComp;
                                        }

                                        if (taxComp.length > 0) {
                                            Object.assign(dueHeadDetails.Tax, taxComp);
                                        }

                                        if (costTaxComp.length > 0) {
                                            Object.assign(dueHeadDetails.Tax, costTaxComp);
                                        }

                                        insDueList = dueHeadDetails;
                                        if (remainingAmount == 0) {

                                        }
                                    }
                                });
                                break;


                            case "4": // For Merchandise
                                amountCreditInput.Dues.Merchandise.forEach(merch => {
                                    if (merch.Amount > 0 && remainingAmount > 0) {
                                        let costCompTaxList: IUnitDistributionHead[] = [];
                                        let taxCompTaxList: IUnitDistributionHead[] = [];
                                        let costTaxCompTaxList: IUnitDistributionHead[] = [];
                                        merch.Tax.sort(o => o.TaxAppliedFor);
                                        merch.Tax.forEach(tax => {
                                            let taxHeadDistribution: IUnitDistributionHead = <IUnitDistributionHead>{};
                                            if (tax.TaxAppliedFor == 155) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                costCompTaxList.push(taxHeadDistribution);
                                            }

                                            if (tax.TaxAppliedFor == 156) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                taxCompTaxList.push(taxHeadDistribution);
                                            }

                                            if (tax.TaxAppliedFor == 157) {
                                                taxHeadDistribution.TaxId = tax.Id;
                                                taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                costTaxCompTaxList.push(taxHeadDistribution);
                                            }
                                        });
                                        let taxDistribution: ITaxDistribution = <ITaxDistribution>{};
                                        taxDistribution.CostComp = costCompTaxList;
                                        taxDistribution.TaxComp = taxCompTaxList;
                                        taxDistribution.CostTaxComp = costTaxCompTaxList;
                                        let dueHeadDetails: IMerchandiseDueHeadDetails = <IMerchandiseDueHeadDetails>{};

                                        merch.Tax = merch.Tax && merch.Tax.length ? merch.Tax : [];
                                        var paymentDistribution = this.paymentDistribution(remainingAmount, merch.Amount, merch.Tax.reduce((total, t) => +total + t.AppliedTaxAmount, 0), taxDistribution);
                                        dueHeadDetails.Amount = (-1) * paymentDistribution.UnitHeadPayment;
                                        dueHeadDetails.Id = merch.Id;
                                        dueHeadDetails.Tax = [];
                                        remainingAmount = paymentDistribution.RemainingAmountToPay;
                                        merch.Amount = paymentDistribution.HeadAmountToBeDistributed;
                                        merch['taxAmountToBeDistributed'] = paymentDistribution.TotalTaxToBeDistributed;

                                        merch.Tax.map(dueTax => {
                                            if (dueTax.TaxAppliedFor == 155) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.CostCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }

                                            if (dueTax.TaxAppliedFor == 156) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.TaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }
                                            if (dueTax.TaxAppliedFor == 157) {
                                                dueTax.AppliedTaxAmount = paymentDistribution.CostTaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                            }

                                        });
                                        var costComp = [];
                                        paymentDistribution.CostCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            costComp.push(obj);
                                        });
                                        var taxComp = [];
                                        paymentDistribution.TaxCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            taxComp.push(obj);
                                        });
                                        var costTaxComp = [];
                                        paymentDistribution.CostTaxCompTaxPayment.map(ele => {
                                            let obj: ITaxInfoModel = {
                                                TaxChargeId: ele.ChargesTaxId,
                                                Id: ele.Id,
                                                AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                Percentage: 0,
                                                TaxAppliedFor: 0,
                                                fromDate: '',
                                                toDate: '',
                                                TaxPayment: 0,
                                                TaxAllowance: 0,
                                                UnchargedRecord: false,
                                                TaxAmountToPay: 0,
                                            };
                                            costTaxComp.push(obj);
                                        });
                                        if (costComp.length > 0) {
                                            dueHeadDetails.Tax = costComp;
                                        }

                                        if (taxComp.length > 0) {
                                            Object.assign(dueHeadDetails.Tax, taxComp);
                                        }

                                        if (costTaxComp.length > 0) {
                                            Object.assign(dueHeadDetails.Tax, costTaxComp);
                                        }

                                        merchDueList = dueHeadDetails;
                                        if (remainingAmount == 0) {

                                        }
                                    }
                                });

                                break;
                        }


                    }
                }
            }

            if (rentDueList) {
                duesreturn.Rent.push(rentDueList);
            }
            if (feeDueList) {
                duesreturn.Fees.push(feeDueList);
            }
            if (insDueList) {
                duesreturn.Insurance.push(insDueList);
            }
            if (merchDueList) {
                duesreturn.Merchandise.push(merchDueList);
            }

        }
        let amountCreditOuput: IAmountCreditOuputModel = {
            AmountCredit: duesreturn,
            RemainingDues: amountCreditInput.Dues,
            Cycle: periodCycle
        }
        return amountCreditOuput;
    }

    getJSONDateString(dateInput) {
        return new Date(new Date(dateInput.toString().split('Z')[0])).toDateString();
    }


    ///////================ Period Wise Transfer Credit Distribution=================//////////////
    getAmountCreditedPeriodWise(paymentOrder: any[], amountCreditInput: IAmountCreditInputModel) {
        let remainingAmount = 0;
        let duesreturn: IDuesModel = <IDuesModel>{};
        let periodCycle = 0;
        duesreturn.Rent = [];
        duesreturn.Fees = [];
        duesreturn.Insurance = [];
        duesreturn.Merchandise = [];
        duesreturn.LateLienCharge = [];
        remainingAmount = amountCreditInput.Allowance * -1;

        let periodwiseDate = []
        amountCreditInput.Dues.Rent.forEach(ele => {
            if (periodwiseDate.indexOf(this.getJSONDateString(ele.FromDate)) == -1) {
                periodwiseDate.push(this.getJSONDateString(ele.FromDate))
            }
        });

        amountCreditInput.Dues.Fees.forEach(ele => {
            if (periodwiseDate.indexOf(this.getJSONDateString(ele.FromDate)) == -1) {
                periodwiseDate.push(this.getJSONDateString(ele.FromDate))
            }
        });

        amountCreditInput.Dues.Insurance.forEach(ele => {
            if (periodwiseDate.indexOf(this.getJSONDateString(ele.FromDate)) == -1) {
                periodwiseDate.push(this.getJSONDateString(ele.FromDate))
            }
        });

        amountCreditInput.Dues.Merchandise.forEach(ele => {
            if (periodwiseDate.indexOf(this.getJSONDateString(ele.FromDate)) == -1) {
                periodwiseDate.push(this.getJSONDateString(ele.FromDate))
            }
        });

        periodwiseDate = periodwiseDate
            .sort(function (a, b) {
                const date1 = new Date(a)
                const date2 = new Date(b)

                return date1.getTime() - date2.getTime();
            })

        // var distributedAmount: IDistributedAmountDetails;
        if (remainingAmount > 0) {
            if (amountCreditInput.Dues.Deposit.Amount > 0) {
                duesreturn.Deposit = {
                    Amount: 0,
                    Name: '',
                    Tax: [],
                    Id: 0,
                    FromDate: new Date(),
                    ToDate: new Date(),
                    Head: 0,
                    RentalChargeId: 0,
                    LedgerId: 0,
                    AmountPaid: 0,
                    AllowancePaid: 0,
                    AmountToPay: 0,
                    LateLienDate: new Date(),
                    PaidThroughDate: new Date()
                };
                if (remainingAmount > amountCreditInput.Dues.Deposit.Amount) {
                    duesreturn.Deposit.Amount = amountCreditInput.Dues.Deposit.Amount;
                } else {
                    duesreturn.Deposit.Amount = remainingAmount;
                }

                if (amountCreditInput.Dues.Deposit.Amount < remainingAmount) {
                    amountCreditInput.Dues.Deposit.Amount = 0;
                }
                else {
                    amountCreditInput.Dues.Deposit.Amount = amountCreditInput.Dues.Deposit.Amount - remainingAmount;
                }
                remainingAmount = remainingAmount - duesreturn.Deposit.Amount;
                duesreturn.Deposit = {
                    Amount: duesreturn.Deposit.Amount,
                    Name: '',
                    Tax: [],
                    Id: amountCreditInput.Dues.Deposit.Id,
                    FromDate: new Date(),
                    ToDate: new Date(),
                    Head: 0,
                    RentalChargeId: 0,
                    LedgerId: 0,
                    AmountPaid: 0,
                    AllowancePaid: 0,
                    AmountToPay: 0,
                    LateLienDate: new Date(),
                    PaidThroughDate: new Date()
                };
                //remainingAmount = distributedAmount.RemainingAllowanceAmount;
                // amountCreditInput.Dues.Deposit.Amount = amountCreditInput.Dues.Deposit.Amount - remainingAmount;//distributedAmount.UnitHeadPayment;
            }
            let intIndex = 0;


            let arrPaymentOrderSequence = paymentOrder;

            var rentDueList: IDueHeadDetails[];
            var feeDueList: IFeesDueHeadDetails[];
            var insDueList: IInsuranceDueHeadDetails[];
            var merchDueList: IMerchandiseDueHeadDetails[];
            var lateLienDueList: IDueHeadDetails[];

            rentDueList = [];
            feeDueList = [];
            insDueList = [];
            merchDueList = [];
            if (arrPaymentOrderSequence != null && arrPaymentOrderSequence.length != 0) {
                for (periodCycle = 0; periodCycle < periodwiseDate.length; periodCycle++) {
                    let intPayOrderIndex = 0;
                    let periodFromdate = periodwiseDate[periodCycle]
                    for (intIndex = intPayOrderIndex; intIndex < arrPaymentOrderSequence.length; intIndex++) {
                        if (remainingAmount > 0) {
                            switch (arrPaymentOrderSequence[intIndex]) {
                                case "1": // For Rent
                                    // try
                                    // {
                                    let rentInfo = amountCreditInput.Dues.Rent.filter(x => this.getJSONDateString(x.FromDate) == periodFromdate);
                                    if (rentInfo && rentInfo.length) {
                                        rentInfo.forEach(rent => {
                                            if (rent.Amount > 0 && remainingAmount > 0) {
                                                let costCompTaxList: IUnitDistributionHead[] = [];
                                                let taxCompTaxList: IUnitDistributionHead[] = [];
                                                let costTaxCompTaxList: IUnitDistributionHead[] = [];
                                                if (rent.Tax && rent.Tax.length) {
                                                    rent.Tax.sort(o => o.TaxAppliedFor);
                                                    rent.Tax.forEach(tax => {
                                                        let taxHeadDistribution: IUnitDistributionHead = <IUnitDistributionHead>{};
                                                        if (tax.TaxAppliedFor == 155) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            costCompTaxList.push(taxHeadDistribution);
                                                        }

                                                        if (tax.TaxAppliedFor == 156) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            taxCompTaxList.push(taxHeadDistribution);
                                                        }

                                                        if (tax.TaxAppliedFor == 157) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            costTaxCompTaxList.push(taxHeadDistribution);
                                                        }
                                                    });
                                                }
                                                let taxDistribution: ITaxDistribution = <ITaxDistribution>{};
                                                taxDistribution.CostComp = costCompTaxList;
                                                taxDistribution.TaxComp = taxCompTaxList;
                                                taxDistribution.CostTaxComp = costTaxCompTaxList;
                                                let dueHeadDetails: IDueHeadDetails = <IDueHeadDetails>{};

                                                rent.Tax = rent.Tax && rent.Tax.length ? rent.Tax : [];
                                                var paymentDistribution = this.paymentDistribution(remainingAmount, rent.Amount, rent.Tax.reduce((total, t) => +total + t.AppliedTaxAmount, 0), taxDistribution);
                                                dueHeadDetails.Amount = (-1) * paymentDistribution.UnitHeadPayment;
                                                dueHeadDetails.Id = rent.Id;
                                                dueHeadDetails.Tax = [];
                                                remainingAmount = paymentDistribution.RemainingAmountToPay;
                                                rent.Amount = paymentDistribution.HeadAmountToBeDistributed;
                                                rent['taxAmountToBeDistributed'] = paymentDistribution.TotalTaxToBeDistributed;
                                                //fees.Tax = distributedAmount.RemainingTaxHeadDues;
                                                rent.Tax.map(dueTax => {
                                                    if (dueTax.TaxAppliedFor == 155) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.CostCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }

                                                    if (dueTax.TaxAppliedFor == 156) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.TaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }
                                                    if (dueTax.TaxAppliedFor == 157) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.CostTaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }

                                                });

                                                var costComp = [];
                                                paymentDistribution.CostCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = <ITaxInfoModel>{};
                                                    obj.TaxChargeId = ele.ChargesTaxId,
                                                        obj.Id = ele.Id,
                                                        obj.AppliedTaxAmount = (-1) * ele.TaxAmountToPay,
                                                        obj.Percentage = 0,
                                                        obj.TaxAppliedFor = 0,
                                                        obj.fromDate = '',
                                                        obj.toDate = ''
                                                    costComp.push(obj);
                                                });

                                                var taxComp = [];
                                                paymentDistribution.TaxCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };

                                                    taxComp.push(obj);
                                                });
                                                var costTaxComp = [];
                                                paymentDistribution.CostTaxCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        TaxAppliedFor: 0,
                                                        Percentage: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };

                                                    costTaxComp.push(obj);
                                                });
                                                if (costComp.length > 0) {
                                                    dueHeadDetails.Tax = costComp;
                                                }

                                                if (taxComp.length > 0) {
                                                    Object.assign(dueHeadDetails.Tax, taxComp); // dueHeadDetails.Tax.push(taxComp);
                                                }

                                                if (costTaxComp.length > 0) {
                                                    Object.assign(dueHeadDetails.Tax, costTaxComp);//dueHeadDetails.Tax.push(costTaxComp);
                                                }

                                                rentDueList.push(dueHeadDetails);
                                                if (remainingAmount == 0) {

                                                }

                                                // }


                                            }
                                        });
                                    }
                                    break;


                                case "2": // For Fees ,Late Fees,Lien Charge
                                    let feeInfo = amountCreditInput.Dues.Fees.filter(x => this.getJSONDateString(x.FromDate) == periodFromdate);
                                    if (feeInfo && feeInfo.length) {
                                        feeInfo.forEach(fee => {
                                            if (fee.Amount > 0 && remainingAmount > 0) {
                                                let costCompTaxList: IUnitDistributionHead[] = [];
                                                let taxCompTaxList: IUnitDistributionHead[] = [];
                                                let costTaxCompTaxList: IUnitDistributionHead[] = [];
                                                if (fee.Tax && fee.Tax.length) {
                                                    fee.Tax.sort(o => o.TaxAppliedFor);
                                                    fee.Tax.forEach(tax => {
                                                        let taxHeadDistribution: IUnitDistributionHead = <IUnitDistributionHead>{};
                                                        if (tax.TaxAppliedFor == 155) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            costCompTaxList.push(taxHeadDistribution);
                                                        }

                                                        if (tax.TaxAppliedFor == 156) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            taxCompTaxList.push(taxHeadDistribution);
                                                        }

                                                        if (tax.TaxAppliedFor == 157) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            costTaxCompTaxList.push(taxHeadDistribution);
                                                        }
                                                    });
                                                }
                                                let taxDistribution: ITaxDistribution = <ITaxDistribution>{};
                                                taxDistribution.CostComp = costCompTaxList;
                                                taxDistribution.TaxComp = taxCompTaxList;
                                                taxDistribution.CostTaxComp = costTaxCompTaxList;
                                                let dueHeadDetails: IFeesDueHeadDetails = <IFeesDueHeadDetails>{};

                                                fee.Tax = fee.Tax && fee.Tax.length ? fee.Tax : [];
                                                var paymentDistribution = this.paymentDistribution(remainingAmount, fee.Amount, fee.Tax.reduce((total, t) => +total + t.AppliedTaxAmount, 0), taxDistribution);
                                                dueHeadDetails.Amount = (-1) * paymentDistribution.UnitHeadPayment;
                                                dueHeadDetails.Id = fee.Id;
                                                dueHeadDetails.Tax = [];
                                                remainingAmount = paymentDistribution.RemainingAmountToPay;
                                                fee.Amount = paymentDistribution.HeadAmountToBeDistributed;
                                                fee['taxAmountToBeDistributed'] = paymentDistribution.TotalTaxToBeDistributed;

                                                fee.Tax.map(dueTax => {
                                                    if (dueTax.TaxAppliedFor == 155) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.CostCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }

                                                    if (dueTax.TaxAppliedFor == 156) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.TaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }
                                                    if (dueTax.TaxAppliedFor == 157) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.CostTaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }

                                                });
                                                var costComp = [];
                                                paymentDistribution.CostCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    costComp.push(obj);
                                                });
                                                var taxComp = [];
                                                paymentDistribution.TaxCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    taxComp.push(obj);
                                                });
                                                var costTaxComp = [];
                                                paymentDistribution.CostTaxCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    costTaxComp.push(obj);
                                                });
                                                if (costComp.length > 0) {
                                                    dueHeadDetails.Tax = costComp;
                                                }

                                                if (taxComp.length > 0) {
                                                    Object.assign(dueHeadDetails.Tax, taxComp);
                                                }

                                                if (costTaxComp.length > 0) {
                                                    Object.assign(dueHeadDetails.Tax, costTaxComp);
                                                }

                                                feeDueList.push(dueHeadDetails);
                                                if (remainingAmount == 0) {

                                                }
                                                // }
                                            }
                                        });
                                    }
                                    break;

                                case "3": // For Insurance
                                    let insuranceInfo = amountCreditInput.Dues.Insurance.filter(x => this.getJSONDateString(x.FromDate) == periodFromdate);
                                    if (insuranceInfo && insuranceInfo.length) {
                                        insuranceInfo.forEach(ins => {

                                            if (ins.Amount > 0 && remainingAmount > 0) {
                                                let costCompTaxList: IUnitDistributionHead[] = [];
                                                let taxCompTaxList: IUnitDistributionHead[] = [];
                                                let costTaxCompTaxList: IUnitDistributionHead[] = [];
                                                if (ins.Tax && ins.Tax.length) {
                                                    ins.Tax.sort(o => o.TaxAppliedFor);
                                                    ins.Tax.forEach(tax => {
                                                        let taxHeadDistribution: IUnitDistributionHead = <IUnitDistributionHead>{};
                                                        if (tax.TaxAppliedFor == 155) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            costCompTaxList.push(taxHeadDistribution);
                                                        }

                                                        if (tax.TaxAppliedFor == 156) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            taxCompTaxList.push(taxHeadDistribution);
                                                        }

                                                        if (tax.TaxAppliedFor == 157) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            costTaxCompTaxList.push(taxHeadDistribution);
                                                        }
                                                    });
                                                }
                                                let taxDistribution: ITaxDistribution = <ITaxDistribution>{};
                                                taxDistribution.CostComp = costCompTaxList;
                                                taxDistribution.TaxComp = taxCompTaxList;
                                                taxDistribution.CostTaxComp = costTaxCompTaxList;
                                                let dueHeadDetails: IInsuranceDueHeadDetails = <IInsuranceDueHeadDetails>{};

                                                ins.Tax = ins.Tax && ins.Tax.length ? ins.Tax : [];
                                                var paymentDistribution = this.paymentDistribution(remainingAmount, ins.Amount, ins.Tax.reduce((total, t) => +total + t.AppliedTaxAmount, 0), taxDistribution);
                                                dueHeadDetails.Amount = (-1) * paymentDistribution.UnitHeadPayment;
                                                dueHeadDetails.Id = ins.Id;
                                                dueHeadDetails.Tax = [];
                                                remainingAmount = paymentDistribution.RemainingAmountToPay;
                                                ins.Amount = paymentDistribution.HeadAmountToBeDistributed;
                                                ins['taxAmountToBeDistributed'] = paymentDistribution.TotalTaxToBeDistributed;

                                                ins.Tax.map(dueTax => {
                                                    if (dueTax.TaxAppliedFor == 155) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.CostCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }

                                                    if (dueTax.TaxAppliedFor == 156) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.TaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }
                                                    if (dueTax.TaxAppliedFor == 157) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.CostTaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }

                                                });

                                                var costComp = [];
                                                paymentDistribution.CostCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    costComp.push(obj);
                                                });
                                                var taxComp = [];
                                                paymentDistribution.TaxCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    taxComp.push(obj);
                                                });
                                                var costTaxComp = [];
                                                paymentDistribution.CostTaxCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    costTaxComp.push(obj);
                                                });
                                                if (costComp.length > 0) {
                                                    dueHeadDetails.Tax = costComp;
                                                }

                                                if (taxComp.length > 0) {
                                                    Object.assign(dueHeadDetails.Tax, taxComp);
                                                }

                                                if (costTaxComp.length > 0) {
                                                    Object.assign(dueHeadDetails.Tax, costTaxComp);
                                                }

                                                insDueList.push(dueHeadDetails);
                                                if (remainingAmount == 0) {

                                                }
                                                // }
                                            }
                                        });
                                    }
                                    break;


                                case "4": // For Merchandise
                                    let merchInfo = amountCreditInput.Dues.Merchandise.filter(x => (x.FromDate).toDateString() == periodFromdate);
                                    if (merchInfo && merchInfo.length) {
                                        merchInfo.forEach(merch => {
                                            if (merch.Amount > 0 && remainingAmount > 0) {
                                                let costCompTaxList: IUnitDistributionHead[] = [];
                                                let taxCompTaxList: IUnitDistributionHead[] = [];
                                                let costTaxCompTaxList: IUnitDistributionHead[] = [];
                                                if (merch.Tax && merch.Tax.length) {
                                                    merch.Tax.sort(o => o.TaxAppliedFor);
                                                    merch.Tax.forEach(tax => {
                                                        let taxHeadDistribution: IUnitDistributionHead = <IUnitDistributionHead>{};
                                                        if (tax.TaxAppliedFor == 155) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            costCompTaxList.push(taxHeadDistribution);
                                                        }

                                                        if (tax.TaxAppliedFor == 156) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            taxCompTaxList.push(taxHeadDistribution);
                                                        }

                                                        if (tax.TaxAppliedFor == 157) {
                                                            taxHeadDistribution.TaxId = tax.Id;
                                                            taxHeadDistribution.Amount = tax.AppliedTaxAmount;
                                                            taxHeadDistribution.ChargesTaxId = tax.TaxChargeId;
                                                            costTaxCompTaxList.push(taxHeadDistribution);
                                                        }
                                                    });
                                                }
                                                let taxDistribution: ITaxDistribution = <ITaxDistribution>{};
                                                taxDistribution.CostComp = costCompTaxList;
                                                taxDistribution.TaxComp = taxCompTaxList;
                                                taxDistribution.CostTaxComp = costTaxCompTaxList;
                                                let dueHeadDetails: IMerchandiseDueHeadDetails = <IMerchandiseDueHeadDetails>{};

                                                merch.Tax = merch.Tax && merch.Tax.length ? merch.Tax : [];
                                                var paymentDistribution = this.paymentDistribution(remainingAmount, merch.Amount, merch.Tax.reduce((total, t) => +total + t.AppliedTaxAmount, 0), taxDistribution);
                                                dueHeadDetails.Amount = (-1) * paymentDistribution.UnitHeadPayment;
                                                dueHeadDetails.Id = merch.Id;
                                                dueHeadDetails.Tax = [];
                                                remainingAmount = paymentDistribution.RemainingAmountToPay;
                                                merch.Amount = paymentDistribution.HeadAmountToBeDistributed;
                                                merch['taxAmountToBeDistributed'] = paymentDistribution.TotalTaxToBeDistributed;

                                                merch.Tax.map(dueTax => {
                                                    if (dueTax.TaxAppliedFor == 155) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.CostCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }

                                                    if (dueTax.TaxAppliedFor == 156) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.TaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }
                                                    if (dueTax.TaxAppliedFor == 157) {
                                                        dueTax.AppliedTaxAmount = paymentDistribution.CostTaxCompTaxPayment.find(o => o.ChargesTaxId == dueTax.TaxChargeId).TaxAmountToBeDistributed;
                                                    }

                                                });
                                                var costComp = [];
                                                paymentDistribution.CostCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    costComp.push(obj);
                                                });
                                                var taxComp = [];
                                                paymentDistribution.TaxCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    taxComp.push(obj);
                                                });
                                                var costTaxComp = [];
                                                paymentDistribution.CostTaxCompTaxPayment.map(ele => {
                                                    let obj: ITaxInfoModel = {
                                                        TaxChargeId: ele.ChargesTaxId,
                                                        Id: ele.Id,
                                                        AppliedTaxAmount: (-1) * ele.TaxAmountToPay,
                                                        Percentage: 0,
                                                        TaxAppliedFor: 0,
                                                        fromDate: '',
                                                        toDate: '',
                                                        TaxPayment: 0,
                                                        TaxAllowance: 0,
                                                        UnchargedRecord: false,
                                                        TaxAmountToPay: 0,
                                                    };
                                                    costTaxComp.push(obj);
                                                });
                                                if (costComp.length > 0) {
                                                    dueHeadDetails.Tax = costComp;
                                                }

                                                if (taxComp.length > 0) {
                                                    Object.assign(dueHeadDetails.Tax, taxComp);
                                                }

                                                if (costTaxComp.length > 0) {
                                                    Object.assign(dueHeadDetails.Tax, costTaxComp);
                                                }

                                                merchDueList.push(dueHeadDetails);
                                                if (remainingAmount == 0) {

                                                }
                                                // }
                                            }
                                        });
                                    }
                                    break;
                            }


                        }
                    }

                    if (rentDueList != null && rentDueList.length > 0) {
                        duesreturn.Rent = duesreturn.Rent.concat(rentDueList);
                    }
                    if (feeDueList != null && feeDueList.length > 0) {
                        duesreturn.Fees = duesreturn.Fees.concat(feeDueList);
                    }
                    if (insDueList != null && insDueList.length > 0) {
                        duesreturn.Insurance = duesreturn.Insurance.concat(insDueList);
                    }
                    if (merchDueList != null && merchDueList.length > 0) {
                        duesreturn.Merchandise = duesreturn.Merchandise.concat(merchDueList);
                    }
                }
            }

        }
        let amountCreditOuput: IAmountCreditOuputModel = {
            AmountCredit: duesreturn,
            RemainingDues: amountCreditInput.Dues,
            Cycle: periodCycle
        }
        return amountCreditOuput;
    }

    paymentDistribution(remainingAmount: number, headAmount: number, totalTaxAmount: number, taxAmount: ITaxDistribution) {
        let response: IDistributedResponse = <IDistributedResponse>{};
        let unitheadPayment = 0;
        let calculatedTax = 0;
        let totalTax = totalTaxAmount;
        var costCompPaymentList = []; // : IUnitHeadTaxPayment
        var taxCompPaymentList = []; // : IUnitHeadTaxPayment
        var costTaxCompPaymentList = []; // : IUnitHeadTaxPayment
        let distributedAmount = headAmount + totalTax;
        if (remainingAmount >= distributedAmount) {
            unitheadPayment = headAmount;
            taxAmount.CostComp.forEach(tax => {
                let taxPayment: IUnitHeadTaxPayment = <IUnitHeadTaxPayment>{};
                taxPayment.Id = tax.TaxId;

                taxPayment.TaxAmountToPay = tax.Amount;
                calculatedTax += tax.Amount;
                taxPayment.TaxAmountToBeDistributed = 0;
                taxPayment.ChargesTaxId = tax.ChargesTaxId;
                costCompPaymentList.push(taxPayment);
            });

            taxAmount.TaxComp.forEach(tax => {
                let taxPayment: IUnitHeadTaxPayment = <IUnitHeadTaxPayment>{};
                taxPayment.Id = tax.TaxId;
                calculatedTax += tax.Amount;
                taxPayment.TaxAmountToPay = tax.Amount;
                taxPayment.TaxAmountToBeDistributed = 0;
                taxPayment.ChargesTaxId = tax.ChargesTaxId;
                taxCompPaymentList.push(taxPayment);
            });

            taxAmount.CostTaxComp.forEach(tax => {
                let taxPayment: IUnitHeadTaxPayment = <IUnitHeadTaxPayment>{};
                taxPayment.Id = tax.TaxId;
                calculatedTax += tax.Amount;
                taxPayment.TaxAmountToPay = tax.Amount;
                taxPayment.TaxAmountToBeDistributed = 0;
                taxPayment.ChargesTaxId = tax.ChargesTaxId;
                costTaxCompPaymentList.push(taxPayment);
            });

            response.CostCompTaxPayment = costCompPaymentList;
            response.TaxCompTaxPayment = taxCompPaymentList;
            response.CostTaxCompTaxPayment = costTaxCompPaymentList;

            response.RemainingAmountToPay = parseFloat((remainingAmount - distributedAmount).toFixed(2)); //remainingAmount
            response.HeadAmountToBeDistributed = 0;
            response.UnitHeadPayment = unitheadPayment;
            response.TotalTax = calculatedTax;//total tax distributed
            response.TotalTaxToBeDistributed = totalTax - calculatedTax;

            response.PaymentPaidThruDate = new Date();
        } else {

            let paidPercentage = 0;
            paidPercentage = parseFloat(((remainingAmount * 100) / distributedAmount).toFixed(2));
            unitheadPayment = parseFloat(((headAmount * paidPercentage) / 100).toFixed(2));

            taxAmount.CostComp.forEach(tax => {
                let taxPayment: IUnitHeadTaxPayment = <IUnitHeadTaxPayment>{};
                taxPayment.Id = tax.TaxId;
                taxPayment.TaxAmountToPay = parseFloat(((tax.Amount * paidPercentage) / 100).toFixed(2));
                calculatedTax += taxPayment.TaxAmountToPay;
                taxPayment.ChargesTaxId = tax.ChargesTaxId;
                taxPayment.TaxAmountToBeDistributed = parseFloat((tax.Amount - taxPayment.TaxAmountToPay).toFixed(2));
                costCompPaymentList.push(taxPayment);
            });

            taxAmount.TaxComp.forEach(tax => {
                let taxPayment: IUnitHeadTaxPayment = <IUnitHeadTaxPayment>{};
                taxPayment.Id = tax.TaxId;
                taxPayment.TaxAmountToPay = parseFloat(((tax.Amount * paidPercentage) / 100).toFixed(2));
                calculatedTax += taxPayment.TaxAmountToPay;
                taxPayment.ChargesTaxId = tax.ChargesTaxId;
                taxPayment.TaxAmountToBeDistributed = parseFloat((tax.Amount - taxPayment.TaxAmountToPay).toFixed(2));
                taxCompPaymentList.push(taxPayment);
            });

            taxAmount.CostTaxComp.forEach(tax => {
                let taxPayment: IUnitHeadTaxPayment = <IUnitHeadTaxPayment>{};
                taxPayment.Id = tax.TaxId;
                taxPayment.TaxAmountToPay = parseFloat(((tax.Amount * paidPercentage) / 100).toFixed(2));
                calculatedTax += taxPayment.TaxAmountToPay;
                taxPayment.ChargesTaxId = tax.ChargesTaxId;
                taxPayment.TaxAmountToBeDistributed = parseFloat((tax.Amount - taxPayment.TaxAmountToPay).toFixed(2));
                costTaxCompPaymentList.push(taxPayment);
            });

            if (remainingAmount == parseFloat((unitheadPayment + calculatedTax).toFixed(2))) {
                response.CostCompTaxPayment = costCompPaymentList;
                response.TaxCompTaxPayment = taxCompPaymentList;
                response.CostTaxCompTaxPayment = costTaxCompPaymentList;
                remainingAmount = 0;

                response.UnitHeadPayment = unitheadPayment;//urc
                response.HeadAmountToBeDistributed = headAmount - unitheadPayment;//remaining head amount
                response.TotalTaxToBeDistributed = totalTax - calculatedTax;//remaining head amount
                response.TotalTax = calculatedTax;//total tax distributed
                response.RemainingAmountToPay = 0; //cash amount
                response.PaymentPaidThruDate = new Date();
            } else {
                var mismatch = remainingAmount - parseFloat((unitheadPayment + calculatedTax).toFixed(2));
                var appliedamount = parseFloat((remainingAmount).toFixed(2));
                var calculatedamount = parseFloat((unitheadPayment + calculatedTax).toFixed(2));
                var res = appliedamount > calculatedamount ? 1 : -1;
                var total = 0;
                var costCompPaymentListTotal = costCompPaymentList.forEach(x =>
                    total += x.TaxAmountToPay);
                total = 0;
                var taxCompPaymentListTotal = taxCompPaymentList.forEach(x =>
                    total += x.TaxAmountToPay);
                total = 0;
                var costTaxCompPaymentListTotal = costTaxCompPaymentList.forEach(x =>
                    total += x.TaxAmountToPay);
                if (costCompPaymentList.length != 0) {

                    if (res < 0) { // appliedallowance is less than calculatedAllowance.
                        calculatedTax += mismatch;
                        if (costCompPaymentList.length != 0) costCompPaymentList[0].TaxAmountToPay += mismatch;
                        if (costCompPaymentList.length != 0) costCompPaymentList[0].TaxAmountToBeDistributed -= mismatch;
                    }

                    if (res > 0) { // appliedallowance is greater than calculatedAllowance.
                        calculatedTax += mismatch;
                        if (costCompPaymentList.length != 0) { costCompPaymentList[0].TaxAmountToPay += mismatch; }
                        if (costCompPaymentList.length != 0) { costCompPaymentList[0].TaxAmountToBeDistributed -= mismatch; }
                    }
                } else if (taxCompPaymentList.length != 0) {
                    if (res < 0) { // appliedallowance is less than calculatedAllowance.
                        calculatedTax += mismatch;
                        taxCompPaymentList[0].TaxAmountToPay += mismatch;
                        taxCompPaymentList[0].TaxAmountToBeDistributed -= mismatch;
                    }

                    if (res > 0) { // appliedallowance is greater than calculatedAllowance.
                        calculatedTax += mismatch;
                        taxCompPaymentList[0].TaxAmountToPay += mismatch;
                        taxCompPaymentList[0].TaxAmountToBeDistributed -= mismatch;
                    }
                }
                else if (costTaxCompPaymentList.length != 0) {
                    if (res < 0) { // appliedallowance is less than calculatedAllowance.
                        calculatedTax += mismatch;
                        costTaxCompPaymentList[0].TaxAmountToPay += mismatch;
                        costTaxCompPaymentList[0].TaxAmountToBeDistributed -= mismatch;
                    }

                    if (res > 0) { // appliedallowance is greater than calculatedAllowance.
                        calculatedTax += mismatch;
                        costTaxCompPaymentList[0].TaxAmountToPay += mismatch;
                        costTaxCompPaymentList[0].TaxAmountToBeDistributed -= mismatch;
                    }
                }
                else {
                    if (res < 0) { // appliedallowance is less than calculatedAllowance.
                        unitheadPayment += mismatch;
                        headAmount -= mismatch;
                    }

                    if (res > 0) { // appliedallowance is greater than calculatedAllowance.
                        unitheadPayment += mismatch;
                        headAmount -= mismatch;
                    }
                }


                response.CostCompTaxPayment = costCompPaymentList;
                response.TaxCompTaxPayment = taxCompPaymentList;
                response.CostTaxCompTaxPayment = costTaxCompPaymentList;
                remainingAmount = 0;

                response.UnitHeadPayment = unitheadPayment;//urc
                response.HeadAmountToBeDistributed = headAmount - unitheadPayment;//remaining head amount
                response.TotalTaxToBeDistributed = totalTax - calculatedTax;//remaining head amount
                response.TotalTax = calculatedTax;//total tax distributed
                response.RemainingAmountToPay = 0; //cash amount
                response.PaymentPaidThruDate = new Date();

            }
        }

        return response;
    }

    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 46 || charCode > 57 || charCode == 47)) {
            return false;
        }
        return true;

    }

    roundToTwo(num) {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    omit_special_char(charCode) {
        var k;
        k = charCode;
        return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
    }

    // storeGroupList must be list of storegroups under which we need to detect DM or RM
    // could be where the user is assigned as Approver or Alternate approver1 or Alternate Approver2
    // facilityId is the facility to which the user is DM or RM is being verified
    // assign as RM priority over DM if user is both
    isDMRMStoreGroup(storeGroupList, facilityId) {
        let isUserDmRmForFacility = '';
        if (facilityId != null && facilityId != undefined && facilityId != 0) {
            const storegroupForFacilityExist = storeGroupList.filter(sg =>
                sg.StorageFacilities.findIndex(fac => fac.Id == facilityId) != -1);
            if (storegroupForFacilityExist && storegroupForFacilityExist.length > 0) {
                const indexDM = storegroupForFacilityExist.findIndex(sg => sg.TypeId == this.EApprovalsDMRM.District);
                const indexRM = storegroupForFacilityExist.findIndex(sg => sg.TypeId == this.EApprovalsDMRM.Region);
                isUserDmRmForFacility = indexRM != -1 ? 'RM' : (indexDM != -1 ? 'DM' : '');
            }
        }
        return isUserDmRmForFacility;
    }
    // StorageFacilities

    getFormattedDate(dateInput) {
        const year = parseInt(dateInput.substr(0, 4), 10);
        const month = dateInput.substr(5, 2);
        const date = dateInput.substr(8, 2);

        return month + "/" + date + "/" + year;
    }

    scrollToTop(docElementID: string) {
        var elm = document.getElementById(docElementID);
        if (elm) {
            elm.scrollIntoView(true);
            window.scrollTo(0, 0);
        }
    }

    calculateFeesAmountCostToRent(value, type, prorate: boolean, Recurring: boolean, rent: number, IsExistingcustomer, dateRequired, PrePayPeriods: number,
        billingTypeId, BillingCycleId: number, CollectNextRent: boolean, moveinSettingsPrortateDuringMoveIn, ProrateAtMovein: boolean, ProrateSecondMonth: boolean) {
        // existing tenant or exempt is not checked
        // transfer is not checked
        let feeAmount = 0;
        let changedVal = parseFloat(value);
        let proratedFee = 0;
        let feeValue: number = 0;
        let totalFee: number = 0;
        if (changedVal === 0) {
            return feeAmount;
        }
        if (type === 183) {
            feeValue = rent * (changedVal / 100);
        } else { // else 184 case
            feeValue = changedVal;
        }
        if (BillingCycleId == 163 && prorate == true && /*moveinSettingsPrortateDuringMoveIn &&*/ (ProrateAtMovein == true || ProrateSecondMonth == true)) {
            totalFee = feeValue;
            if (Recurring == false && ProrateSecondMonth) {
                feeValue = 0;
            }
            else {
                feeValue = this.getProratedValue(billingTypeId, feeValue, dateRequired, 0, null);
                // GetProratedValue((long)costToRentInputModel.BillingTypeId, feeValue, costToRentInputModel.DateNeeded, 0, costToRentInputModel.FacilityId, facilityPeriodSettings);
            }
            proratedFee = feeValue;
            if (ProrateSecondMonth == false) {
                feeAmount = feeValue;
                if (CollectNextRent == true && Recurring == true) {
                    feeAmount += totalFee * PrePayPeriods;
                }
            }
            else {
                feeAmount = totalFee;
                if (CollectNextRent == true && Recurring == true) {
                    feeAmount += feeValue + (totalFee * (PrePayPeriods - 1));
                }
            }
        } else {
            if (CollectNextRent == true && Recurring == true) {
                feeAmount = feeValue + (feeValue * PrePayPeriods);
            }
            else {
                feeAmount = feeValue;
            }
        }
        return parseFloat(feeAmount.toFixed(2));
    }

    getDatePartFromAngularDateTime(date: Date) {
        if (date) {
            date = new Date(date);
            const datepipe = new DatePipe('en');
            return datepipe.transform(date, 'MM/dd/yyyy');
        }
        return date;
    }

    replaceXMLEntity(stringValue) {
        if (stringValue && stringValue.trim().length) {
            if (stringValue.includes("&")) {
                stringValue = stringValue.replace(/[&,]/g, "&amp;");
            }
            if (stringValue.includes("<")) {
                stringValue = stringValue.replace("<", "&lt;")
            }
            if (stringValue.includes(">")) {
                stringValue = stringValue.replace(">", "&gt;")
            }
            if (stringValue.includes('"')) {
                stringValue = stringValue.replace('"', "&quot;")
            }
            if (stringValue.includes("'")) {
                stringValue = stringValue.replace("'", "&apos;")
            }
        }
        return stringValue;
    }

    transformDate(date) {
        if (date != null && date != undefined && date != '') {
            const datepipe: DatePipe = new DatePipe('en');
            let dateStr = datepipe.transform(date, 'yyyy-MM-dd');
            return dateStr + 'T00:00:00.000z';
        } else {
            return date;
        }

    }

    formatDate1(dateString: string): string { // Format: dd/MM/yyyy HH:mm A
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format

        const formattedDate = `${month}/${day}/${year} ${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
        return formattedDate;
    }

    isString(val: any): boolean {
        return (val && (typeof val === 'string' || val instanceof String));
    }

    isDateInFormat(dateString: string): boolean {
        const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z)$/i;
        return regex.test(dateString);
    }

    calculateMercDiscount(merchItem, type) {
        let qty = type == 'Return' ? merchItem.ReturnQuantity : merchItem.Quantity;
        let price = type == 'Return' ? merchItem.Price : merchItem.SalesPrice;
        merchItem['TotalMerchDiscount'] = 0;
        merchItem['ItemCost'] = parseFloat(this.decimalPipe.transform((parseFloat(price) * qty), '1.2-2').replace(/,/g, ''));
        if (merchItem.MerchandiseDiscount && merchItem.MerchandiseDiscount.length && merchItem.MerchandiseDiscount.length > 0) {
            merchItem.MerchandiseDiscount.forEach((element) => {
                element['Selected'] = type == 'Return' ? true : element.Selected;
                element['IndividualDiscount'] = 0;
                if (element.Selected) {
                    if (element.Type === EnumMerchDiscount.Percentage || element.Type === EnumDiscount.Percentage) {
                        element.IndividualDiscount = parseFloat(merchItem.ItemCost) * (element.Value / 100);
                    } else if (element.Type === EnumMerchDiscount.Fixed || element.Type === EnumDiscount['Fixed Discount']) {
                        element.IndividualDiscount = element.Value;
                    }
                    element.IndividualDiscount = parseFloat(this.decimalPipe.transform(element.IndividualDiscount, '1.2-2').replace(/,/g, ''));
                    element['DiscountAmt'] = element.IndividualDiscount ? element.IndividualDiscount : 0;
                }

            });
            merchItem.TotalMerchDiscount = merchItem.MerchandiseDiscount.reduce((sum, e) => sum +
                parseFloat(e.IndividualDiscount), 0);
            if (merchItem.TotalMerchDiscount > merchItem.ItemCost) {
                merchItem.TotalMerchDiscount = merchItem.ItemCost;
            }
            merchItem['DiscountAmount'] = merchItem.TotalMerchDiscount;
        } else {
            merchItem.TotalMerchDiscount = 0;
        }
    }

    getReportType(url: string): string {
        const fileExtension = this.getFileExtension(url);
        switch (fileExtension) {
            case 'pdf':
                return 'PDF';
            case 'doc':
            case 'docx':
                return 'Word';
            case 'xls':
            case 'xlsx':
                return 'Excel';
            case 'csv':
                return 'CSV';
            default:
                return 'Unknown';
        }
    }

    private getFileExtension(url: string): string {
        return url.split('.').pop()?.toLowerCase() || '';
    }


    getCardTypeName(type) {
        switch (type) {
            case ECreditCards.American_Express: return 'American Express';
            case ECreditCards.Visa: return 'Visa';
            case ECreditCards.Master_Card: return 'Master Card';
            case ECreditCards.Discover: return 'Discover';
            case ECreditCards.Diners: return 'Diners';
            case ECreditCards.JCB: return 'JCB';
        }
    }

    getPaymentTransactionTypeName(type) {
        switch (type) {
            case EPaymentTransactionType.Processed: return 'Processed';
            case EPaymentTransactionType.Declined: return 'Declined';
            case EPaymentTransactionType.Reversal_Refund: return 'Reversal Refund';
        }
    }

    getPaymentModeName(type) {
        switch (type) {
            case EPaymentModes.Cash: return 'Cash';
            case EPaymentModes.Check: return 'Check';
            case EPaymentModes.CreditCard: return 'Credit Card';
            case EPaymentModes.DirectDebitEFT: return 'Direct Debit EFT';
            case EPaymentModes.HostedPage: return 'Hosted Page';
            case EPaymentModes.ACH: return 'ACH';
            case EPaymentModes.ManualAuthorization: return 'Manual Authorization';
            case EPaymentModes.CardOnfile: return 'Card On File';
            case EPaymentModes.ACHOnFile: return 'ACH On File';
            case EPaymentModes.ACHManualAuthorization: return 'ACH Manual Authorization';
            case EPaymentModes.EFTManualAuthorization: return 'EFT Manual Authorization';
        }
    }

    encodeHtml(str: string): string {
        const element = document.createElement('div');
        if (str) {
            element.innerText = str;
            return element.innerHTML;
        }
        return '';
    }

    areDataEqual(data1: any, data2: any): Boolean {
        return JSON.stringify(data1) === JSON.stringify(data2);
    }

    getDaysInaMonth(date) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        return new Date(year, month, 0).getDate();
    }
}
